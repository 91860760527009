import React, { useState } from "react";

const MenuItemPopup = ({ item, onClose, onConfirm }) => {
  console.log(item, 'item');
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const handleConfirm = () => {
    onConfirm(item, quantity);
    onClose(); // Close the popup after confirming
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg  w-[460px]  mx-2 shadow-lg">
        <h3 className="text-lg font-bold text-center mb-4">
          Məhsul seçimi
        </h3>
        <div className="space-y-2">
          <div className="flex justify-between items-center px-4 py-1 bg-[#f9f9f9] border border-gray-300 rounded-md cursor-pointer hover:bg-[#e6f3ff] hover:border-blue-500 transition duration-300 ease-in-out">
            {item?.name}
          </div>
          <div className="flex justify-between items-center px-4 py-1 bg-[#f9f9f9] border border-gray-300 rounded-md cursor-pointer hover:bg-[#e6f3ff] hover:border-blue-500 transition duration-300 ease-in-out">
            {item?.name}
          </div>
          <div className="flex justify-between items-center px-4 py-1 bg-[#f9f9f9] border border-gray-300 rounded-md cursor-pointer hover:bg-[#e6f3ff] hover:border-blue-500 transition duration-300 ease-in-out">
            {item?.name}
          </div>
        </div>
        <div className="flex justify-center items-center my-4">
          <button
            onClick={decreaseQuantity}
            className="w-[40px] h-[40px] bg-[#007bff] rounded-full text-lg text-white  "
          >
            -
          </button>
          <span className="mx-1 text-lg border px-6 rounded-md">
            {quantity}
          </span>
          <button
            onClick={increaseQuantity}
            className=" h-[40px] w-[40px] bg-[#007bff] text-white  rounded-full "
          >
            +
          </button>
        </div>
        {/* <p className="text-lg font-semibold mb-4">
          Toplam Qiymət : {item.price * quantity} ₼
        </p> */}
        <div className="flex w-full text-xs font-normal space-x-3 justify-between">
          <button
            onClick={handleConfirm}
            className="bg-[#28a745] text-white py-2   w-full  px-4 rounded-sm"
          >
            Masaya Əlavə et
          </button>
          <button
            onClick={onClose}
            className="text-white  w-full py-2  rounded-sm  bg-[#dc3545]"
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuItemPopup;
